import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import $ from "jquery";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
    AllModules,
    GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");

export default class DCTattendancelogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                width: 160,
                resizable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            loadshow: "show-n",
            overly: "",
            rowdata: [],
            sliderbulkupload: "",
            rowModelhighchartoptionsType: "enterprise",
            //   frameworkComponents: {
            //     BatchDownload: BatchDownload,
            //   },
            rowGroupPanelShow: "always",
            statusBar: {

            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            pagetitle: "",
            eventGridAction: "gridAction",
            csvcontent: [],
            file: "",
            batch_name: "",
            cluster_breaks: "2",
            cluster_radius: "0.5",
            notification_emails: "",
            bulkslide: "",
            trailor_no: "",

            rowId: "",
            show: false,
            basicTitle: "",
            basicType: "",
            transitDate: "",
            transpoterGSTDate: "",
            unloadedDate: "",
            reachedDate: "",
            SectororCountry: "",
            location: "",
            stateMetricTon: '',
            stateCBM: '',
            stateSectororCountry: '',
            stateLocation: '',
            shouldReload: false,

            bulkslide1: '',
            locationChange: '',
            employee_id: "",
            employee_name: "",
            location: "",
            login_time: "",
            logout_time: "",

            employeeIDEdit: '',
            locationEdit: '',
            logoutTimeEdit: '',
            loginTimeEdit: '',
            showLoginLogoutInputs: false,
            flagForEdit: false,
            flagForHoliday: false,
            plannedDate: '',
            holidayDate: '',
        };
        // this.downloadbatchfile = this.downloadbatchfile.bind(this);
    }

    async componentDidMount() {
        loadDateTimeScript()
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });

        // Fetch attendance logs
        var params = {
            email: localStorage.getItem("email"),
            userType: localStorage.getItem("user_type")
        };

        await redirectURL
            .post("/consignments/dctattendancelogsMount", params)
            .then((response) => {
                //console.log(response.data, "dctattendancelogs");
                const reversedData = [...response.data].reverse();
                const sortedLogs = reversedData.sort((a, b) => {
                    const timeA = new Date(a.current_time).getTime();
                    const timeB = new Date(b.current_time).getTime();
                    return timeB - timeA; // Sort in descending order
                });

                //console.log(sortedLogs, "sorted logs");
                this.setState({
                    rowdata: sortedLogs,
                    loadshow: "show-n",
                    overly: "show-n",
                });
            })
            .catch((e) => {
                console.log(e);
            });

        loadDateTimeScript();
    }




    addNewRow = () => {
        // var rowId = e.data._id;
        this.setState({
            bulkslide: "slider-translate-30p",
            // rowId: rowId,
            overly: "show-m"
        });
    }

    closeAlert = () => {
        this.setState({
            show: false,
        }, () => {
            if (this.state.shouldReload) {
                window.location.reload();
            }
        })
    };
    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            bulkslide: "",
            bulkslide1: '',
            flagForEdit: false,
            flagForHoliday: false,
            locationChange: { value: "", label: "" }
        });

        $("#planneddatetime").val("")
        $("#Login_time").val("");
    }


    locationChangeHandler = (e) => {
        //console.log(e, 'ratesUpto---------', e.value)
        this.setState({
            flagForEdit: false,
            flagForHoliday: false,
        })
        if (e.value == "SICK LEAVE") {
            this.setState({
                // showLoginLogoutInputs: true,
                flagForEdit: false,
                flagForHoliday: false,
                locationChange: e,
            })
        } else
            if (e.value == "WEEK-OFF") {
                this.setState({
                    // showLoginLogoutInputs: true,
                    flagForEdit: false,
                    flagForHoliday: false,
                    locationChange: e,
                })
            } else
                if (e.value == "SHIFT SWAP") {
                    this.setState({
                        // showLoginLogoutInputs: true,
                        flagForEdit: false,
                        flagForHoliday: false,
                        locationChange: e,
                    })
                }
                else if (e.value == "SUDDEN LEAVE") {
                    this.setState({
                        // showLoginLogoutInputs: true,
                        flagForEdit: false,
                        flagForHoliday: false,
                        locationChange: e,
                    })
                } else if (e.value == "PLANNED LEAVE") {
                    this.setState({
                        locationChange: e,
                        flagForEdit: true,
                        flagForHoliday: false,
                    })
                } else if (e.value == "HOLIDAY") {
                    this.setState({
                        locationChange: e,
                        flagForEdit: false,
                        flagForHoliday: true,
                    })
                } else {
                    this.setState({
                        flagForEdit: false,
                        flagForHoliday: false,
                        locationChange: e,
                    })
                }

    };

    plannedDateSelecter = () => {
        loadDateTimeScript()
        this.setState({
            plannedDate: $("#planneddatetime").val()
        })
    }
    holidayDateSelecter = () => {
        loadDateTimeScript()
        this.setState({
            holidayDate: $("#holidaydatetime").val()
        })
    }


    locationHandler = (e) => {
        // console.log(e,'ratesUpto')
        this.setState({
            location: e.target.value,
        });
    };
    loginTimeHandler = (e) => {
        // console.log(e,'ratesUpto')
        this.setState({
            login_time: e.target.value,
        });
    };
    logoutTimeHandler = (e) => {
        // console.log(e,'ratesUpto')
        this.setState({
            logout_time: e.target.value,
        });
    };


    async onClickShowAttendance(event) {
        event.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        var Location = this.state.locationChange.value

        // if(Location == "PLANNED LEAVE" || Location == "SICK LEAVE" ){
        // console.log('inside')
        const currentDate = new Date();

        const day1 = String(currentDate.getDate()).padStart(2, "0");
        const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year1 = String(currentDate.getFullYear());
        const hours1 = String(currentDate.getHours()).padStart(2, "0");
        const minutes1 = String(currentDate.getMinutes()).padStart(2, "0");

        const Login_time = `${day1}-${month1}-${year1} ${hours1}:${minutes1}`;
        var params = {

            // Employee_id :Employee_id,
            Employee_name: localStorage.getItem("username"),
            employee_email: localStorage.getItem("email"),
            Location: Location,
            Login_time: Login_time,
            plannedDate: this.state.plannedDate,
            holidayDate: this.state.holidayDate
            // Logout_time : Logout_time
        };

        //console.log(params, "257----");

        if (Location !== undefined && Location !== "undefined" && Location !== null && Location !== "") {

            await redirectURL
                .post("/consignments/dctattendancelogs", params)
                .then(async (response) => {
                    //console.log(response, "224")
                    // console.log(response.data.message,'response.data.status')
                    if (response.data.message == "success") {

                        this.setState({
                            basicTitle: "Successfully updated",
                            basicType: "success",
                            show: true,
                            bulkslide: "",
                            overly: "show-n",
                            loadshow: "show-n",
                            flagForEdit: false,
                            flagForHoliday: false,
                            locationChange: { value: "", label: "" }
                        })
                        this.onClickHideAll();
                        await this.componentDidMount();

                        $("#planneddatetime").val("")
                        $("#holidaydatetime").val("")
                        $("#Login_time").val("");
                        //  $("#Logout_time").val("");
                    } else {
                        this.setState({
                            basicTitle: response.data.message,
                            basicType: "warning",
                            show: true,
                            bulkslide: "",
                            flagForEdit: false,
                            flagForHoliday: false,
                            locationChange: { value: "", label: "" }
                        })
                        this.onClickHideAll();
                        await this.componentDidMount();
                        $("#Login_time").val("");
                        $("#planneddatetime").val("")
                        $("#holidaydatetime").val("")

                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({
                basicTitle: "Select Location",
                basicType: "warning",
                show: true,
                bulkslide: "",
                overly: "show-n",
                loadshow: "show-n",
            })
        }

    }
    handleInputChange(e) {
        this.setState({
            bulkslide: "slider-translate-30p",
            // rowId: rowId,
        });


    }

    async onCellClicked(e) {
        if (e.colDef.field === "Logout") {
            // console.log('hello',e.data)
            this.setState({
                flagForEdit: false,
                flagForHoliday: false,
            })
            if (e.data.location == "PLANNED LEAVE" || e.data.location == "SICK LEAVE" || e.data.location == "SUDDEN LEAVE" || e.data.location == "HOLIDAY") {

                //  console.log('inside')
            } else {
                this.setState({
                    rowId: e.data._id,
                    //  employee_id:e.data.employee_id,
                    employee_name: e.data.employee_name,
                    location: e.data.location,
                    login_time: e.data.login_time,
                    logout_time: e.data.logout_time,
                    //         bulkslide1: "slider-translate-30p",
                    // overly: "show-m",
                });
                if (this.state.logout_time == "" || this.state.logout_time == null || this.state.logout_time == "Invalid Date") {
                    this.onClickEditAttendsnceData()
                } else {
                }
            }

        }

        if (e.colDef.field === "Approve") {
            var cnf = window.confirm("Are you sure you want to approve this attendance record?");
            if (cnf === true) {
                var params = {
                    rowId: e.data._id,
                    is_approved_by: localStorage.getItem("email")
                }
                await redirectURL
                    .post("/consignments/approveattendance", params)
                    .then(async (response) => {
                        //console.log(response, "224")
                        //console.log(response.data.message, 'response.data.status')
                        if (response.data.message == "success") {

                            this.setState({
                                basicTitle: "Successfully Approved",
                                basicType: "success",
                                show: true,
                                overly: "show-n",
                                loadshow: "show-n",
                                shouldReload: true,
                            })
                        } else {
                            this.setState({
                                basicTitle: response.data.message,
                                basicType: "warning",
                                show: true,
                            })
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });

            }
        }
    }

    onClickEditAttendsnceData() {
        // event.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        // var Employee_id = this.state.employee_id;
        var Location = this.state.location
        var Login_time1 = this.state.login_time
        // var Logout_time =$("#edit_Logout_time").val()




        // Create a new Date object from the input string
        const dateObject2 = new Date(Login_time1);

        // Extract the date components
        const day2 = String(dateObject2.getUTCDate()).padStart(2, "0");
        const month2 = String(dateObject2.getUTCMonth() + 1).padStart(2, "0");
        const year2 = String(dateObject2.getUTCFullYear());
        const hours2 = String(dateObject2.getUTCHours()).padStart(2, "0");
        const minutes2 = String(dateObject2.getUTCMinutes()).padStart(2, "0");

        // Create the formatted date string
        const Login_time = `${day2}-${month2}-${year2} ${hours2}:${minutes2}`;

        // console.log(Login_time,'formattedDate212121');


        const currentDate = new Date();

        const day1 = String(currentDate.getDate()).padStart(2, "0");
        const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year1 = String(currentDate.getFullYear());
        const hours1 = String(currentDate.getHours()).padStart(2, "0");
        const minutes1 = String(currentDate.getMinutes()).padStart(2, "0");

        const Logout_time = `${day1}-${month1}-${year1} ${hours1}:${minutes1}`;
        // console.log(Logout_time,'presentDateTimeFormatted');

        //console.log(Login_time, 'Login_time', Logout_time);

        var params = {
            rowId: this.state.rowId,
            // Employee_id: Employee_id,
            Employee_name: localStorage.getItem("username"),
            Location: Location,
            Login_time: Login_time,
            Logout_time: Logout_time
        };

        // console.log(params, "257edit----");
        var cnf = window.confirm("Are you sure, you want to Logout ? ");
        if (cnf === true) {
            redirectURL
                .post("/consignments/editdctattendancelogs", params)
                .then((response) => {
                    //console.log(response, "224")
                    if (response.data.message == "Invalid input: logout time is earlier than login time.") {
                        alert("Invalid input: logout time is earlier than login time.")
                    } else {
                        this.setState({
                            basicTitle: "Logout Successfully",
                            basicType: "success",
                            show: true,
                            bulkslide: "",
                            overly: "show-n",
                            loadshow: "show-n"
                            // login_time:Login_time,
                            // logout_time:Logout_time,
                        })
                        // this.onClickHideAll();
                        this.componentDidMount();


                        $("#edit_Login_time").val("")
                        $("#edit_Logout_time").val("")
                        // $("#Employee_id").val("");
                        $("#Location").val("");

                    }


                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({
                overly: "show-n",
                loadshow: "show-n"
            })
        }

    }

    render() {
        var hideForUsers = localStorage.getItem("email") == "amit.chaurasia@heromotocorp.com"
        const batchwisecolumns = [
            {
                pinned: "left",
                headerName: "",
                field: "Logout",
                width: 200,
                resizable: true,
                editable: false,
                hide: hideForUsers,
                suppressColumnsToolPanel: hideForUsers,
                cellRenderer: function (param) {
                    if (param.data.login_time !== "" && param.data.login_time !== "" && param.data.login_time !== undefined && param.data.login_time !== null) {
                        if (param.data.login_duration == "") {
                            var rendComponent =
                                '<button class="btn btn-arms" id="buttonStyling" style="background-color: #00c292; color: white;">Click to Logout</button>';
                            return rendComponent;
                        } else {
                            var rendComponent =
                                '<button class="btn btn-arms" disabled="on" id="buttonStyling" style="background-color: #00c292; color: white;">Click to Logout</button>';
                            return rendComponent;
                        }
                    }
                    else {
                        var rendComponent =
                            '<button class="btn btn-arms" disabled="on" id="buttonStyling" style="background-color: #00c292; color: white;">Click to Logout</button>';
                        return rendComponent;
                    }
                },
                filter: false,
            },
            {
                pinned: "left",
                headerName: "",
                field: "Approve",
                width: 100,
                resizable: true,
                editable: false,
                hide: !hideForUsers,
                suppressColumnsToolPanel: !hideForUsers,
                cellRenderer: function (param) {
                    const isDisabled = param.data && param.data.is_attendance_approved == 1
                    if (isDisabled) {
                        return `<div style="text-align: center;">Approved</div>`;
                    } else {
                        return `
                            <div style="text-align: center;">
                                <button class="btn btn-arms" id="approveAttendance" style="background-color: #297ba2; color: white; padding:2%;" title="Approve">
                                    Approve
                                </button>
                            </div>`;
                    }
                },
                filter: false,
            },

            // {
            //   headerName: "Employee ID",
            //   field: "employee_id",

            // },

            {
                headerName: "Employee Name",
                field: "employee_name",
            },
            {
                headerName: "Work Location",
                field: "location",
            },
            // {
            //   headerName: "Planned Leave Date",

            //   field: "planned_leave_date",
            //   valueGetter: (param) => {
            //       if (param.data.planned_leave_date !== "") {
            //         return getHyphenDDMMMYYYY(param.data.planned_leave_date);
            //       } else {
            //         return "";
            //       }
            //     },
            // },
            {
                headerName: "Login time",

                field: "login_time",
                valueGetter: (param) => {
                    if (param.data.login_time !== "") {
                        return getHyphenDDMMMYYYYHHMM(param.data.login_time);
                    } else {
                        return "";
                    }
                },
            },
            {
                headerName: "Logout time",

                field: "logout_time",
                valueGetter: (param) => {
                    if (param.data.logout_time !== "") {
                        return getHyphenDDMMMYYYYHHMM(param.data.logout_time);
                    } else {
                        return "";
                    }
                },
            },
            {
                headerName: "Login Duration",

                field: "login_duration",

            },
            {
                headerName: "Date",

                field: "current_time",
                valueGetter: (param) => {

                    if (param.data.login_time !== "") {
                        return getHyphenDDMMMYYYY(param.data.current_time);
                    } else {
                        return "";
                    }

                },
            },
        ];
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>

                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12"> */}
                    <div className="card" style={{ width: "100%" }}>
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                <span>DCT Attendance Tracking</span>
                            </h5>
                            {!hideForUsers ?
                                <button className="btn btn-success" style={{ float: "right" }} onClick={this.addNewRow.bind(this)}>Punch Attendance </button> : ""}
                        </div>

                        <div>

                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div
                                    id="myGrid"
                                    style={{ width: "100%", height: "478px" }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={batchwisecolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowdata}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        rowClassRules={this.state.rowClassRules}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        // onCellClicked={this.onCellRowClicked.bind(this)}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                {console.log(this.state.bulkslide)}
                <div
                    className={"slide-r " + this.state.bulkslide}
                    style={{ overflow: "hidden" }}
                >
                    <h3 className="subH">
                        Punch Attendance
                        <span
                            className="float-right fclose"
                            style={{
                                marginRight: "12px",
                                padding: "1px 8px",
                                marginTop: "-2px",
                            }}
                            onClick={this.onClickHideAll.bind(this)}
                        >
                            x
                        </span>
                    </h3>

                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form
                                method="post"
                                onSubmit={this.onClickShowAttendance.bind(this)}
                                autocomplete="off"
                                className="row col-xl-12 col-lg-12"
                            >
                                <div
                                    className="form-group col-xl-12 col-lg-12"
                                    style={{ paddingLeft: "0px", paddingRight: "27px" }}
                                >
                                    <label>
                                        Employee Name :{" "}
                                    </label>
                                    <input
                                        type="text"
                                        name="Employee_name"
                                        id="Employee_name"
                                        className="form-control"
                                        placeholder={localStorage.getItem("username")}
                                        //   onChange={this.CBMchangeHandler}
                                        required
                                        disabled
                                    />
                                </div>
                                <div
                                    className="form-group col-xl-12 col-lg-12"
                                    style={{ paddingLeft: "0px", paddingRight: "27px" }}
                                >
                                    <label>
                                        Work Location :{" "}
                                    </label>
                                    <Select
                                        placeholder={"Location"}
                                        closeMenuOnSelect={true}
                                        value={this.state.locationChange}
                                        // value={this.state.container_name}
                                        // className={"col-xl-12 col-lg-12 border-radius-0"}
                                        onChange={this.locationChangeHandler.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        // options={this.selectCountryOptionsItems()}
                                        options={[
                                            {
                                                label: "GURGAON",
                                                value: "GURGAON",
                                            },
                                            // {
                                            //     label: "MANESAR",
                                            //     value: "MANESAR",
                                            // },
                                            {
                                                label: "WORK FROM HOME",
                                                value: "WORK FROM HOME",
                                            },
                                            {
                                                label: "PLANNED LEAVE",
                                                value: "PLANNED LEAVE",
                                            },
                                            {
                                                label: "UNINFORMED LEAVE",
                                                value: "UNINFORMED LEAVE",
                                            },
                                            {
                                                label: "SICK LEAVE",
                                                value: "SICK LEAVE",
                                            },
                                            {
                                                label: "SUDDEN LEAVE",
                                                value: "SUDDEN LEAVE",
                                            },
                                            {
                                                label: "WEEK-OFF",
                                                value: "WEEK-OFF",
                                            },
                                            {
                                                label: "SHIFT SWAP",
                                                value: "SHIFT SWAP",
                                            },
                                            {
                                                label: "HOLIDAY",
                                                value: "HOLIDAY",
                                            }

                                        ]}

                                        required
                                    />
                                </div>

                                {this.state.flagForEdit == true ?
                                    <div
                                        className="form-group col-xl-12 col-lg-12"
                                        style={{ paddingLeft: "0px", paddingRight: "27px" }}
                                    >
                                        <label>
                                            Select Leave Date :
                                        </label>
                                        <input
                                            type="date"
                                            name="planneddatetime"
                                            id="planneddatetime"
                                            className="form-control"
                                            placeholder="PLANNED LEAVE"
                                            // disabled={this.state.showLoginLogoutInputs}
                                            onChange={this.plannedDateSelecter.bind(this)}

                                            required
                                        />
                                    </div>
                                    : ""}
                                {this.state.flagForHoliday == true ?
                                    <div
                                        className="form-group col-xl-12 col-lg-12"
                                        style={{ paddingLeft: "0px", paddingRight: "27px" }}
                                    >
                                        <label>
                                            Select Holiday Date :
                                        </label>
                                        <input
                                            type="date"
                                            name="holidaydatetime"
                                            id="holidaydatetime"
                                            className="form-control"
                                            // placeholder="PLANNED LEAVE"
                                            // disabled={this.state.showLoginLogoutInputs}
                                            onChange={this.holidayDateSelecter.bind(this)}
                                            required
                                        />
                                    </div>
                                    : ""}
                                <div className="form-group col-xl-3 col-lg-3">
                                    <button type="submit" className="btn btn-success">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}


function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    // console.log(params, "params");
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        timepicker: false,
        changeMonth: true,
        changeYear: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function loadDateTimeScript() {
    // alert("timeDate");
    $(".datetimepicker_datew").datetimepicker({
        //mask:'39-19-9999',
        format: "d-m-Y H:i",
        minDate: true,
        timepicker: true,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false,
    });
    $(".datetimepicker_dateo").datetimepicker({
        //mask:'39-19-9999',
        format: "d-m-Y",
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false,
    });
    $(".cargo_date").datetimepicker({
        //mask:'39-19-9999',
        format: "d-m-Y",
        minDate: true,
        timepicker: false,
    });
}
function formatDate(dateString) {
    if (dateString != "Invalid Date" && dateString != "" && dateString != "NaN-NaN-NaN" && dateString != undefined && dateString != 'undefined') {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    else {
        return ""
    }
}
